export default class Constants {
  static ENDPOINT = {
    ORDER: 'https://postorder.winitout.com',
    PROD: 'https://prod.winitout.com',
    CACHE: 'https://cache.winitout.com',
    FRONT: 'https://order.winitout.com',
    CIBLER: 'https://qa.winitout.com',
    QA: 'https://qa.winitout.com',
    LOCAL: 'http://localhost:8080',
    CDN: 'https://cdn.winitout.com',
    RENDER: {
      PROD: 'https://prod.cibler.io',
      CACHE: 'https://cache.cibler.io',
      CDN: 'https://cdn.cibler.io',
      CIBLER: 'https://qa.cibler.io',
      QA: 'https://qa.cibler.io',
      LOCAL: 'http://localhost:8080',
    },
  };

  static USER_STATUS = {
    AUTHENTICATED: 'authenticated',
    ANONYMOUS: 'anonymous',
  };

  static USER_SEGMENT = {
    ANONYMOUS: 'ANONYMOUS',
    INACTIF: 'INACTIF',
    PROSPECT: 'PROSPECT',
    TIER1: 'TIER1',
    TIER2: 'TIER2',
    TIER3: 'TIER3',
    TIER4: 'TIER4',
  };

  static PAGE_TYPE = {
    UNKNOWN: '',
    LOGIN: 'login',
    CUSTOMER_ZONE: 'customer_zone',
    ORDER_CONFIRMATION: 'order_confirmation',
    DELIVERY_CHOICE: 'delivery',
    PAYMENT_CHOICE: 'payment',
    CART: 'cart',
    PRODUCT_PAGE: 'product_page',
    PRODUCT_LIST: 'product_list',
    HOME: 'home',
    SHOWCASE: 'showcase',
  };

  static EVENT = {
    CLICK: 'click',
    EXIT: 'exit',
    ORDER: 'order',
    ADD_TO_BASKET: 'addToBasket',
    PAGEVIEW: 'pageview',
    CODE_ERROR: 'promoCodeError',
    CODE_SUCCESS: 'promoCodeSuccess',
    SUCCESSVIEW: 'successView',
    SUCCESSCLICK: 'successClick',
    SHOW_OPERATION: 'showOperation',
    DISCOVER_BLOCK: 'discoverBlock',
    ACCEPT_BLOCK: 'acceptBlock',
    REFUSE_BLOCK: 'refuseBlock',
  };

  static geolocStorage1 = 'ABTastyGeoloc';
  static geolocStorage2 = 'DCInfos';
  static emailStorageKey = 'ciblerUserEmail';
  static customerStorageKey = 'ciblerUserInfo';
  static cartStorageKey = 'ciblerCartData';
  static landingPageStorageKey = 'landingPageContext';
  static sessionDataKey = 'ciblerSessionData';
  static campaignsStorageKey = 'ciblerCampaignsTrack';
}
