import Utils from '../utils';
import Constants from '../constants';

export default class Core {
  constructor() {
    if (window.WIOCONFIG) return;

    window.initCiblerCore = () => {
      window.removeEventListener('mouseover', window.initCiblerCore);
      window.removeEventListener('scroll', window.initCiblerCore);
      clearTimeout(this.timer);
      setTimeout(() => {
        this.init();
      }, 1000);
    };

    this.ciblerTriggerCampaign = Utils.getQueryString('ciblerTriggerCampaign');
    this.triggerUserGroup = Utils.getQueryString('triggerUserGroup');

    if (this.ciblerTriggerCampaign || this.triggerUserGroup) {
      this.clearLocalData(); // Clear lock when triggered
      setTimeout(() => {
        window.initCiblerCore();
      }, 100);
    } else {
      Utils.ciblerready(() => {
        if (Utils.getQueryString('cibler_auth')) {
          window.initCiblerCore();
        } else {
          this.timer = setTimeout(() => {
            window.initCiblerCore();
          }, 1000);
          window.addEventListener('mouseover', window.initCiblerCore, { passive: true });
          window.addEventListener('scroll', window.initCiblerCore, { passive: true });
        }
      });
    }
    this.handleSinglePageApp();
  }

  clearLocalData = () => {
    localStorage.removeItem('ciblerDailyLock');
    localStorage.removeItem('ciblerDailyCampaignLock');
  };

  init() {
    const config = sessionStorage.getItem('ciblerConfig');
    if (config && Utils.isStringJson(config) && !this.ciblerTriggerCampaign) {
      this.initCallback(config);
      return;
    }

    Utils.getAjax(
      `${Constants.ENDPOINT.RENDER.CACHE}/api/customers/${this.customerId}/configuration`,
      this.initCallback,
      () => undefined,
    );
  }

  initCallback = (result) => {
    const config = JSON.parse(result);

    sessionStorage.setItem('ciblerConfig', result);

    if (!localStorage.getItem('ciblerOpeningRate')) {
      localStorage.setItem('ciblerOpeningRate', Math.random() * 100);
    }

    const features = {};
    if (config.featureList) {
      config.featureList.forEach(f => (features[f.key] = f.enabled));
    }

    const openRand = parseFloat(localStorage.getItem('ciblerOpeningRate'));
    window.WIOCONFIG = {
      ...window.WIOCONFIG,
      ...config,
      features,
      openRand,
    };

    if (config.openingRate === null || openRand < config.openingRate || this.ciblerTriggerCampaign) {
      this.insertTracking(config);
      this.insertRender(config);
    }
  };

  insertTracking = (config) => {
    const trackingScript = document.getElementById('ciblerTrackingScript');
    if (trackingScript) return;

    let url = `${Constants.ENDPOINT.CDN}/static/${this.customerId}/`;
    if (config.trackingVersion) {
      url = `${Constants.ENDPOINT.CDN}/static/`;
      if (config.customTracking) {
        url = `${url}${this.customerId}/`;
      } else {
        url = `${url}common/`;
      }
      url = `${url}${config.trackingVersion}/`;
    }

    window.WIOCONFIG.trackingUrl = url;
    const s = document.createElement('script');
    s.setAttribute('id', 'ciblerTrackingScript');
    s.setAttribute('src', `${url}wiotracking.js`);
    document.head.appendChild(s);
  };

  insertRender = (config) => {
    if (!window.WIOCONFIG.renderEnabled) return;
    const renderScript = document.getElementById('ciblerRenderScript');
    if (renderScript) return;

    let url = `${Constants.ENDPOINT.RENDER.CDN}/static/${this.customerId}/`;
    if (config.renderVersion) {
      url = `${Constants.ENDPOINT.RENDER.CDN}/static/`;
      if (config.customRender) {
        url = `${url}${this.customerId}/`;
      } else {
        url = `${url}common/`;
      }
      url = `${url}${config.renderVersion}/`;
    }

    window.WIOCONFIG.renderUrl = url;
    const s = document.createElement('script');
    s.setAttribute('id', 'ciblerRenderScript');
    s.setAttribute('src', `${url}wiorender.js`);
    document.head.appendChild(s);
  };

  handleSinglePageApp = () => {
    var _wr = function(type) {
      var orig = history[type];
      return function() {
        var rv = orig.apply(this, arguments);
        var e = new Event(type);
        e.arguments = arguments;
        window.dispatchEvent(e);
        return rv;
      };
    };

    window.removeEventListener('replaceState', this.replaceStateHandler);
    window.removeEventListener('pushState', this.pushStateHandler);

    this.replaceStateHandler = function (e) {
      window.WIORENDER = null;
      window.WIO && window.WIO.constructor && window.WIO.constructor();
    };
    this.pushStateHandler = function (e) {
      console.log('fubar');
      window.WIORENDER = null;
      window.WIO && window.WIO.constructor && window.WIO.constructor();
    };

    history.pushState = _wr('pushState');
    history.replaceState = _wr('replaceState');
    window.addEventListener('replaceState', this.replaceStateHandler);
    window.addEventListener('pushState', this.pushStateHandler);
  }
}
